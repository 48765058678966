import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/Layouts/AppLayout.component';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationFrom } from '../../redux/Actions/calender.action';
import { setLeadsDetails, setLoading } from '../../redux/Actions/user.action';
import { getAll } from '../../services/API';
import { installerLeadsDemoData } from '../../utils/InstallerDemoData';
import useAuth from '../../auth/AuthProvider';
import InstallerSidebar from './InstallerSidebar/InstallerSidebar';
import './Installer.style.css';

export default function Installer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const demo = useSelector((state) => state.userReducer.demo);
  const [selectedLead, setSelectedLead] = useState(null);

  useEffect(() => {
    const getLeads = async () => {
      dispatch(setLoading(true));
      const res = await getAll('users', `getLeads`);
      if (res.data && res.data.length > 0) {
        dispatch(setLoading(false));
        dispatch(setLeadsDetails(res.data));
      }
      if (res.error) {
        console.log(res.error);
        // setError(true);
        // setErrorMessage(res.error.message);
        return null;
      }
      dispatch(setLoading(false));
    };

    if (user) {
      if (demo) {
        dispatch(setLeadsDetails(installerLeadsDemoData));
      } else {
        getLeads();
      }
    }
  }, [demo]);

  const menu = [
    {
      key: 1,
      name: 'Home',
      icon: 'home',
      onclick: () => {
        dispatch(setNavigationFrom('/home'));
        navigate('/installer/home');
      }
    },
    {
      key: 2,
      name: 'Leads',
      icon: 'leads',
      onclick: () => {
        dispatch(setNavigationFrom('/lead-management'));
        navigate('/installer/lead-management');
      }
    },
    {
      key: 3,
      name: 'Calendar',
      icon: 'qualifiedLeads',
      onclick: () => {
        dispatch(setNavigationFrom('/qualified-leads'));
        navigate('/installer/qualified-leads');
      }
    },
    {
      key: 4,
      name: 'Installations',
      icon: 'orders',
      onclick: () => {
        dispatch(setNavigationFrom('/current-orders'));
        navigate('/installer/current-orders');
      }
    }
  ];

  return (
    <AppLayout menu={menu} installer withLogin={true}>
      <div className="container-wide flex-cols-nowrap">
        <div className="installer-container">
          <InstallerSidebar />
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
