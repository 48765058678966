import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/Layouts/AppLayout.component';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWithBackdropComponent from '../../components/Common/Loader/LoadingWithBackdrop.component';
import Alert from '../../components/Common/Alerts/Alert.component';
import { getInstallerfetchUrl } from '../../utils/HelperFunctions';
import { post, getAll } from '../../services/API';
import { setFormValue } from '../../redux/Actions/forms.action';
import {
  setResidentID,
  setResidentDetails,
  setJourneyDetails,
  setDetailDataSubmissionId
} from '../../redux/Actions/user.action';
import useAuth from '../../auth/AuthProvider';
import axios from 'axios';

export default function Resident() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, aloading } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiCalled, setApiCalled] = useState(false);
  const submission_id = useSelector(
    (state) => state.formReducer.hpAssesmentForm.submission_detail?.submission_id
  );
  const property_id = useSelector(
    (state) => state.formReducer.addressForm.submission_detail?.property_id
  );
  const addressLookupValues = useSelector((state) => state.formReducer.addressForm);
  const menu = [
    // {
    //   name: 'Home',
    //   icon: 'home',
    //   onclick: () => navigate('/resident/resident-home', { state: { fromApp: true } }),
    // },
  ];
  useEffect(() => {
    if (user && !apiCalled && !aloading) {
      setLoading(true);
      getResidentAndJourney();
      setApiCalled(true);
    }
  }, [user]);
  const getResidentAndJourney = async () => {
    try {
      const journeyRes = await getJourney();
      await getResident(journeyRes);
      setLoading(false);
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  // get journey
  const getJourney = async () => {
    axios.defaults.withCredentials = true;
    const res = await getAll('users', `getJourney`);

    // const res = await instance.get(`${process.env.REACT_APP_SERVER_URL}/users/getJourney`);
    if (res.data && res.data.journey_id) {
      let journey_details = res.data;
      if (journey_details.installer_selected && journey_details.selected_installer[0].logo) {
        const logo_url = await getInstallerfetchUrl(journey_details.selected_installer[0]);
        journey_details.selected_installer[0].logo_url = logo_url;
      }

      dispatch(setJourneyDetails(journey_details));
      dispatch(setDetailDataSubmissionId(journey_details.form_submissions));
      return journey_details;
    }
    if (res.error) {
      throw new Error(
        "Sorry, something went wrong. We have let our developers know and we'll get back to you once the issue is fixed."
      );
    }
  };
  // get journey
  const getResident = async (journey_details) => {
    const res = await getAll('users', `getResident`);
    if (res.data && res.data.length > 0) {
      dispatch(setResidentID(res.data[0].resident_id));
      dispatch(setResidentDetails(res.data[0]));
      if (res.data[0].temp_form_submission_id !== submission_id && submission_id) {
        await submitHPR(journey_details);
      } else {
        return journey_details;
      }
    }
    if (res.error) {
      throw new Error(res.error.message);
    }
  };

  const submitHPR = async (journey_details) => {
    axios.defaults.withCredentials = true;
    // fetch temp submissions
    const url = 'form/requestTemp';
    const resTemp = await getAll(url, submission_id);
    if (resTemp.data) {
      // user has previous submissions so update the existing ones
      if (journey_details.assessment_complete === 2) {
        await updateHPR(journey_details, resTemp.data);
      }
      // new submission
      else {
        if (Object.keys(resTemp.data).length > 0) {
          const hpr_id = journey_details?.form_submissions?.find(
            (submission) => submission.form_description === 'heat_pump_readiness'
          )?.form_submission_id;
          const assumption_id = journey_details?.form_submissions?.find(
            (submission) => submission.form_description === 'heat_pump_assumptions'
          )?.form_submission_id;
          const obj = {
            hpResponse: resTemp.data,
            property_id: property_id,
            hpr_id: hpr_id,
            post_code: addressLookupValues.address.address_lookup_postcode.replace(/\s/g, ''),
            assumptions_id: assumption_id ? assumption_id : ''
          };
          const url = assumption_id ? `updateHPRFormMetrics/true` : `updateHPRFormMetrics`;
          const parameter = url;
          const res = await post('users', parameter, obj);
          if (res.data) {
            getJourney();
          }
          if (res.error) {
            setError(true);
            setErrorMessage(res.error.message);
          }
        } else {
          getJourney();
        }
      }
    }
    // clear redux
    dispatch(setFormValue('SET_HP_ASSESMENT_Form', {}));
    dispatch(setFormValue('SET_FINAL_ADDRESS', {}));
  };

  const updateHPR = async (journey_details, resTemp) => {
    axios.defaults.withCredentials = true;
    const assumptions_id = journey_details?.form_submissions?.find(
      (submission) => submission.form_description === 'heat_pump_assumptions'
    )?.form_submission_id;
    const hpr_id = journey_details?.form_submissions?.find(
      (submission) => submission.form_description === 'heat_pump_readiness'
    )?.form_submission_id;
    const obj = {
      hpResponse: resTemp,
      property_id: property_id,
      post_code: addressLookupValues.address.address_lookup_postcode.replace(/\s/g, ''),
      assumptions_id: assumptions_id,
      hpr_id: hpr_id
    };
    const res = await post('users', `updateHPRFormMetrics/true`, obj);
    if (res.data) {
      getJourney();
    }
    if (res.error) {
      setError(true);
      setErrorMessage(res.error.message);
    }
  };
  return (
    <>
      {loading || aloading ? (
        <div>
          <LoadingWithBackdropComponent />
        </div>
      ) : (
        <div>
          <AppLayout menu={menu} installer={false} withLogin={true}>
            {error ? <Alert type="error" message={errorMessage} /> : <Outlet />}
          </AppLayout>
        </div>
      )}
    </>
  );
}
