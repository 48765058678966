import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { icons } from '../../../utils/Icons';
import Hamburger from '../../../components/Common/Hamburger/Hamburger';
import './InstallerSidebar.style.css';
import { Divider, Link } from '@mui/material';
import { useInstallerActivePage } from '../../../hooks/useInstallerActivePage';
import { background } from 'storybook/internal/theming';
import useAuth from '../../../auth/AuthProvider';
import { getAll } from '../../../services/API';
import { setHomeStats, setLoading } from '../../../redux/Actions/user.action';
import { installerHomeStatsDemoData } from '../../../utils/InstallerDemoData';
import { useDispatch, useSelector } from 'react-redux';
import { HelpOutline, HelpOutlineOutlined } from '@mui/icons-material';
const MenuItems = () => {
  const { logout } = useAuth();
  const stats = useSelector((state) => state.userReducer.homeStats);
  const journey_details = useSelector((state) => state.userReducer.journey_details);

  const dispatch = useDispatch();
  const demo = useSelector((state) => state.userReducer.demo);
  const { user } = useAuth();
  const location = useLocation();

  const getHomeStats = async () => {
    const res = await getAll('leads', `getHomeStats`);
    if (res.data) {
      dispatch(setHomeStats(res.data));
    }
    if (res.error) {
      // setError(true);
      // setErrorMessage(res.error.message);
      return null;
    }
  };

  useEffect(() => {
    // if (user) {
    //   if (demo) {
    //     dispatch(setHomeStats(installerHomeStatsDemoData));
    //   } else {

    getHomeStats();
    //   }
    // }
  }, [journey_details?.type]);
  return (
    <ul className="main-sidebar">
      {/* Sidebar items */}
      <div className="sidebar-items">
        <li>
          <NavLink
            to="/installer/dashboard"
            className={({ isActive }) => (isActive ? 'active bg-bg2' : '')}>
            {icons['homeSmile']} Dashboard
          </NavLink>
        </li>
        <li>
          <Divider className="my-3" />
        </li>
        <li>
          <NavLink
            to="/installer/bookings"
            className={({ isActive }) => (isActive ? 'active bg-dark-grey' : '')}>
            {icons['arrowCircleRightEmerald']} Bookings (
            {stats?.installations?.active_installation_bookings +
              stats?.survey?.active_survey_bookings}
            )
          </NavLink>
        </li>
        <li>
          <Divider className="my-3" />
        </li>
        <li>
          <NavLink
            to="/installer/estimates"
            className={({ isActive }) => (isActive ? 'active bg-dark-grey' : '')}>
            <div className="position-relative">
              {stats?.leads.pending_leads > 0 && <span className="dot top-right"></span>}
              {icons['arrowCircleRightEmerald']}{' '}
            </div>{' '}
            Estimates ({(stats?.leads?.active_leads ?? 0) + (stats?.leads?.pending_leads ?? 0)})
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/installer/surveys"
            className={({ isActive }) => (isActive ? 'active bg-blue-half' : '')}>
            <div className="position-relative">
              {stats?.survey.awaiting_installer > 0 && <span className="dot top-right"></span>}
              {icons['fileCheck']}
            </div>
            Surveys (
            {(stats?.survey?.awaiting_resident ?? 0) + (stats?.survey?.awaiting_installer ?? 0)})
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/installer/installations"
            className={({ isActive }) => (isActive ? 'active bg-mint' : '')}>
            <div className="position-relative">
              {stats?.installations.awaiting_installer > 0 && (
                <span className="dot  top-right"></span>
              )}
              {icons['packageCheck']}
            </div>
            Installations (
            {(stats?.installations?.awaiting_resident ?? 0) +
              (stats?.installations?.awaiting_installer ?? 0)}
            )
          </NavLink>
        </li>
        <li>
          <Divider className="my-3" />
        </li>
        <li>
          <NavLink
            to="/installer/calendar"
            className={({ isActive }) => (isActive ? 'active bg-dark-grey' : '')}>
            {icons['calendarCheckEmerald']} Calendar
          </NavLink>
        </li>
      </div>

      {/* Logout item */}
      <li className="logout-item">
        <div className="flex-row gap-half">
          <HelpOutlineOutlined className="ms-3" />
          <div className="flex-card gap-half">
            Contact us
            <div className="flex-card gap-half">
              <a href="mailto:example@email.com">enquiries@thermly.co.uk</a>
              <a href="tel:+1234567890">+1 234 567 890</a>
            </div>
          </div>
        </div>
        <li>
          <Divider className="my-3" />
        </li>

        <Link href="#" onClick={() => logout('installer')}>
          {' '}
          {icons['logout']} Logout
        </Link>
      </li>
    </ul>
  );
};

const InstallerSidebar = () => {
  const [isMenuItemsOpen, setIsMenuItemsOpen] = useState(false);
  const activePage = useInstallerActivePage();
  const activePageName = activePage?.name;
  const activePageColor = activePage?.color;
  const toggleMenuItems = () => {
    setIsMenuItemsOpen(!isMenuItemsOpen);
  };

  return (
    <div className="installer-sidebar">
      <div className="d-lg-none">
        {isMenuItemsOpen && <div className="overlay" onClick={toggleMenuItems}></div>}
        <div
          className="flex-space-between slider-active-hamburger"
          style={{ backgroundColor: activePageColor }}>
          <p className="text-standard bold-font emerald-font flex-center">
            <span>{activePageName}</span>
          </p>
          <Hamburger isOpen={isMenuItemsOpen} onClick={toggleMenuItems} />
        </div>
        <div className={`flex-card gap-2 installer-tab-slider ${isMenuItemsOpen ? 'open' : ''}`}>
          <div className="text-align-right">
            <button className="close-grey-button" onClick={() => setIsMenuItemsOpen(false)}>
              {icons['close']}
            </button>
          </div>
          <div className="px-4 h-100">
            <MenuItems />
          </div>
        </div>
      </div>
      <div className="d-tab-none desktop-sidebar">
        <MenuItems />
      </div>
    </div>
  );
};

export default InstallerSidebar;
