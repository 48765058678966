import { createTheme } from '@mui/material/styles';

export const customMuiTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#145A6C',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#f26548',
      contrastText: '#ffffff'
    },
    white: {
      main: '#ffffff',
      contrastText: '#ffffff'
    },
    background: {
      default: '#EAF4F2'
    },
    warning: {
      main: '#f26548'
    },
    info: {
      main: '#dff0f5' // light blue
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#b2b2b2' // Set the color you want for the Divider here
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          borderRadius: '8px' // Set the color you want for the Divider here
        }
      }
    }
    // MuiBreadcrumbs: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiBreadcrumbs-separator': {
    //         color: 'var(--emerald-color)', // Global separator color
    //         fontWeight: 'bold' // Optional for styling
    //       }
    //     }
    //   }
    // }
  }
});
