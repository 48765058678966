import React from 'react';
import './Loader.style.css';

const LoadingWithBackdropComponent = () => (
  <div className="loading" style={{ height: '100vh' }}>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

export default LoadingWithBackdropComponent;
