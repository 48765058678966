import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInstallerActivePage } from '../../../hooks/useInstallerActivePage';
import { icons } from '../../../utils/Icons';
import { capitalizeFirstLetter } from '../../../utils/HelperFunctions';
import { useSelector } from 'react-redux';

const InstallerBreadcrumbsComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const order_details = useSelector((state) => state.userReducer.orders);
  const { journeyId, type } = useParams(); // Get the dynamic parameter (e.g., NS-7468372001)

  const currentRoute = useInstallerActivePage();
  const breadcrumbItems = [
    { label: 'Dashboard', path: '/installer/dashboard' },
    currentRoute &&
      currentRoute?.name !== 'Dashboard' && { label: currentRoute.name, path: location.pathname },
    type && { label: capitalizeFirstLetter(type), path: `/installer/${type}` },
    order_details?.resident_first_name && { label: order_details?.resident_first_name }
    //journeyId && { label: journeyId }
  ].filter(Boolean);

  return (
    <div>
      <Breadcrumbs separator={icons['chevronRight']} aria-label="breadcrumb">
        {breadcrumbItems.map((item, index) => (
          <Link
            key={index}
            onClick={() => navigate(item.path)}
            underline={breadcrumbItems.length - 1 === index ? 'none' : 'always'}
            style={{ textDecorationColor: 'var(--emerald-color)' }}
            className="title-small bold-font">
            {item.label || <Typography color="text.primary">{item.label}</Typography>}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default InstallerBreadcrumbsComponent;
