import { CallApi } from '../utils/APIutil';

let serverUrl = process.env.REACT_APP_SERVER_URL;

export const getAll = async (endpoint, parameter, queryParams = {}) => {
  const token = localStorage.getItem('token');
  // Construct the URL with or without query parameters
  let url = `${serverUrl}/${endpoint}/${parameter}`;

  // If queryParams are provided, append them to the URL
  if (Object.keys(queryParams).length > 0) {
    const queryString = new URLSearchParams(queryParams).toString();
    url = `${url}?${queryString}`;
  }
  const axiosConfig = {
    url: url,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    }
  };
  return await CallApi(axiosConfig);
};

export const getUsers = async (endpoint, getAccessTokenSilently) => {
  const token = localStorage.getItem('token');
  const axiosConfig = {
    url: `${serverUrl}/${endpoint}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return await CallApi(axiosConfig);
};

// export const getSingle = async (endpoint, parameter) => {
//   const token = localStorage.getItem('token');
//   const axiosConfig = {
//     url: `${serverUrl}/${endpoint}/${parameter}`,
//     method: 'GET',
//     headers: {
//       'content-type': 'application/json',
//       authorization: `Bearer ${token}`
//     }
//   };
//   return await CallApi(axiosConfig);
// };

export const post = async (endpoint, parameters, object, accessToken) => {
  const token = localStorage.getItem('token');
  const axiosConfig = {
    url: `${serverUrl}/${endpoint}/${parameters}`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    data: object
  };
  return await CallApi(axiosConfig);
};
export const binaryPost = async (endpoint, parameters, object, accessToken) => {
  const token = localStorage.getItem('token');
  const axiosConfig = {
    url: `${serverUrl}/${endpoint}/${parameters}`,
    method: 'POST',
    headers: {
      /* Authorization: `Bearer ${accessToken}`, */
      'content-type': 'multipart/form-data'
    },
    data: object
  };
  return await CallApi(axiosConfig);
};
export const Filepost = async (endpoint, parameters, object, accessToken) => {
  const token = localStorage.getItem('token');
  const axiosConfig = {
    url: `${serverUrl}/${endpoint}/${parameters}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'multipart/form-data'
    },
    data: object
  };
  return await CallApi(axiosConfig);
};
