import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { customMuiTheme } from './styles/muiTheme';
import AppRoutes from './routes';
import { AuthProvider } from './auth/AuthProvider';
import './App.css';
import './styles/globalLayout.css';
import ReactGA from 'react-ga4';

export default function App() {
  useEffect(() => {
    window.CookiebotCallback_OnAccept = function () {
      if (
        (process.env.REACT_APP_ENV_NAME === 'production' ||
          process.env.REACT_APP_ENV_NAME === 'staging') &&
        window.Cookiebot.consent.marketing
      ) {
        console.log('Initializing GA with consent');
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
        ReactGA.send({ hitType: 'pageview', page: '/', title: 'Landing Page' });
      }
    };
    window.CookiebotCallback_OnDecline = function () {
      if (
        process.env.REACT_APP_ENV_NAME === 'production' ||
        process.env.REACT_APP_ENV_NAME === 'staging'
      ) {
        document.cookie =
          '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=thermly.co.uk;';
      }
    };
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={customMuiTheme}>
        <BrowserRouter>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
