import React from 'react';
import './Hamburger.style.css';
const Hamburger = ({ onClick }) => {
  return (
    <div className={`hamburger`} onClick={onClick}>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

export default Hamburger;
