import { useLocation } from 'react-router-dom';
import Cat from '../../src/images/Illustrations/line_cat.svg';
import Calendar from '../../src/images/Illustrations/line_calendar.svg';
import Plant from '../../src/images/Illustrations/line_plant.svg';
import Time from '../../src/images/Illustrations/thermly_house.svg';
import { HeatPumpOutlined, HomeWorkOutlined } from '@mui/icons-material';

const routesConfig = {
  '/installer/bookings': {
    name: 'Booking',
    color: 'var(--mint-color)',
    direct_image: <HomeWorkOutlined sx={{ fontSize: 60, color: 'var(--emerald-color)' }} />
  },
  '/installer/dashboard': { name: 'Dashboard', color: 'var(--bg2)' },
  '/installer/surveys': { name: 'Surveys', color: 'var(--blue-color-half)', thermly_image: Plant },
  '/installer/estimates': { name: 'Estimates', color: 'var(--dark-grey)', thermly_image: Cat },
  '/installer/installations': {
    name: 'Installations',
    color: 'var(--mint-color)',
    thermly_image: Calendar
  },
  '/installer/calendar': { name: 'Calendar', color: 'var(--dark-grey)' }
};

export const useInstallerActivePage = () => {
  const location = useLocation();
  return routesConfig[location.pathname] || null;
};
